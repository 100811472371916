@import url(https://fonts.googleapis.com/css?family=Muli);
.modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    display: none;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .modal-container {
    position: relative; 
    padding: 1rem;
    min-width: 320px;
    max-width: 880px;
    min-height: 200px;
    max-height: 800px;
    width: 80%;
    overflow-y: auto;
    background-color: #fff;
  }
  
  .modal-close {
    position: absolute;
    top: 0.2rem;
    right: 1rem;
  }
  
  .modal.is-open {
    display: -webkit-flex;
    display: flex;
  }
body{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: #F8F7FC;
}

.fondo-form {
    position: absolute;
    width: 40%;
    height: 100%;
    left: 60%;
    top: 0px;
    bottom: 0px;
    background: #0B1F44;
    color: #F8F7FC;
}

.logo{
    position: absolute;
    left: 80%;
    right: 15.52%;
    top: 7.66%;
    bottom: 84.48%;
}

.button-login{
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    width: 50%;
    background: #44C2FA;
    mix-blend-mode: normal;
    border-radius: 30px;
    color: #F8F7FC;
}

.button-second{
    padding: 5px;
    width: 30%;
    background: #173161;
    border-radius: 30px;
    color: #ACBAD2;
}

.img-ilustracion1{
    height: auto;
    width: 50%;
    margin-top: 1vh;
}

.emonederologo{
    height: auto;
    width: 20%;
    margin-left: 2%;
    margin-top: 1%;
}

.fyttsalogo{
    height: 5vh;
    width: auto;
}

.textprimary{
    color: #5A73E8;
}

.textsecond{
    color: #526C9A;
}

.white{
    color: #ffffff;
}

.whitelight{
    color: #F8F7FC;
}
.colorplaceholder{
    color: #526C9A;
}

.transparent{
    background: transparent;
}
.olvidoclave{
    width:48%; 
    text-align:left;  
    display: inline-block;
}
.login-icons svg{
   margin-left: 20px;
}

.login-icons img{
  width: 30px;
  display: inline !important;
  display: initial !important;
}

/* mitad login*/
.col-md-8 .img-blanco{
   z-index: 1;
   width: 140px;
   margin-left: 60%;
   position: relative;
}
.col-md-8 .img-centro{
  z-index: 0;
  width: 450px;
  display: inline;
  display: initial;
  margin-top: -140px;
  position: relative;
}
.col-md-8 .img-logoFyttsa{
  z-index: 0;
  width: 300px;
  display: inline;
  display: initial;
}
.div-emonedero
{
  width: 250px;
  margin-left: 60px;
  margin-top: 10px;
}
.img-fyt
{
  width: 150px;
  display: inline;
  display: initial;
  margin-top: 10%;
  margin-bottom: 15%;
}

@media screen and (max-width: 768px) {
  .col-6 .img-blanco{
    z-index: 1;
    width: 15vw;
    margin-left: 65%;
    position: relative;
  }
  .col-6 .img-centro{
   z-index: 0;
   width: 50vw;
   display: inline;
   display: initial;
   margin-top: -40px;
   position: relative;
  }
  .col-6 .img-logoFyttsa{
   z-index: 0;
   width: 40vw;
   display: inline;
   display: initial;
  }
  .div-emonedero
  {
    width: 150px;
    margin-left: 0px;
    margin-top: 10px;
  }
  .img-fyt
  {
    display: inline;
    display: initial;
    margin-top: 10%;
    height: 25px;
    width: auto;
  }
  .login .col-6
  {
    font-size: 3.9vw;
  }
  }
  .login input[type="text"], .login input[type="password"]{
    border-radius: 30px !important;
    background: #ffffff !important;
    border-color: #183161 !important;
    color: #003869 !important;
  }
  
  .login .btn{
    border-radius: 20px !important;
  }



/*@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css');*/

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
    padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
    list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    -webkit-text-decoration-skip: ink;
            text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
    width: 100%;
    display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        -webkit-animation-duration: 0.01ms !important;
                animation-duration: 0.01ms !important;
        -webkit-animation-iteration-count: 1 !important;
                animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

body {
    background-color: #ffffff;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: #424147;
}

.class-header
{
    position: absolute;
    z-index: 99;
    text-align: left;
    right: 60px;
}
.banner > div
{
    padding-left: 10%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.titulo1
{
    font-weight: bold;
    color: #143658;
}
.titulo2
{
    font-weight: bold;
    color: #009fe3;
}
.text-azul
{
    color: #009fe3;
}
.hr-short{
height: 0.4rem !important;
width: 30%;
color: #14264b;
border-color: #14264b;
opacity: 0.8;
}
.hr-medium
{
    height: 0.2rem !important;
    color: #14264b;
    border-color: #14264b;
    opacity: 0.8; 
    width: 50%;
}
.div-suscribete
{
    position: relative;
}
.btn-suscribete
{
    font-size: 1.2vw;
    border-radius: 20px;
    bottom: 0px;
    position: absolute;
}
.btn-suscribete-ahora,.btn-suscribete-ahora:hover
{
    background-color: #189ad6;
    color: white;
    width: 100%;
    border-radius:  20px;
    bottom: 0px;
}
.image-app
{
    height: 550px;
    width: 350px;
    display:block;
    margin:auto;
}
.header-div
{
  background-color: #143658;
  height: 70px;
}
.footer
{
    background-color: #16375b;
    color: white;
}
.footer label{
    padding-left: 25px;
}
.img-canjes
{
    width: 300px;
    height: 300px;
    display: block;
    margin: auto;
}
.elemento-center
{
    display: block;
    margin: auto;
}
.carousel-caption{
  top: 7vw;
  left: 50%;
}
/*.carousel-caption img{
  width: 41vw;
}*/
.class-header img{
width: 100%;
height: 3.5rem;
margin-top: 0.5rem;
}
.bannerSlider
{
  margin-top: 5vw;
}

.bannerSlider img:first-child
{
  width: 15vw;
  margin-top: 0.4vw;
  position: absolute;
  left: 19vw;
}
.productCarrousel
{
  background-color: #f2f2f2;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 1px black;
  text-align: center;
}

.productCarrousel img
{
  width: auto;
  height: 25vw;
  display: inline;
  display: initial;
}

.carousel-content > div:nth-child(3) img
{
  width: 17vw;
  height: 17vw;
}
.carousel-content > div:nth-child(2) img
{
  width: 30vw;
  height: 30vw;
}
.carousel-content > div:nth-child(1) img
{
  width: 17vw;
  height: 17vw;
}

.carousel-content > div:nth-child(3)
{
  z-index: 0;
}
.carousel-content > div:nth-child(2)
{
  z-index: 1;
}
.carousel-content > div:nth-child(1)
{
  z-index: 0;
}
.carousel-content > div:nth-child(3) .productCarrousel
{
  width: 60%;
  left: 0;
  right: auto;
  float: left;;
  margin-top: 2vw;
  height: 85%;
}
.carousel-content > div:nth-child(2) .productCarrousel
{
  width: 100%;
  right: auto;
  left: auto;
  float: none;
  float: initial;
  margin-top: 1%;
  height: 98%;
}
.carousel-content > div:nth-child(1) .productCarrousel
{
  width: 60%;
  right: 0;
  left: auto;
  float: right;
  margin-top: 2vw;
  height: 85%;
}

.logoIzqProductos{
  width: 10vw;
  height: 10vw;
  position: absolute;
  bottom: 0px;
  left: 70px
}
.logoDerProductos{
  width: 10vw;
  height: 10vw;
  position: absolute;
  bottom: 0px;
  right: 70px
}

/*anexado de figma*/


.referidos {
    height: 1080px;
    width: 1920px;
    background-color: #ffffff;
  }
  .background {
    height: 1080px;
    width: 1920px;
    background-color: #f8f7fc;
  }
  .rectangle-1-8 {
    border-radius: 0.25rem;
    height: 743px;
    width: 486px;
    background-color: #ffffff;
  }
  .rectangle-1-9 {
    border-radius: 0.25rem;
    height: 743px;
    width: 486px;
    background-color: #ffffff;
  }
  .text-1 {
    text-align: right;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 69.9999988079071%;
    color: #0b1f44;
  }
  .text-2 {
    text-align: left;
    vertical-align: top;
    font-size: 30px;
    font-family: Montserrat;
    line-height: 89.99999761581421%;
    color: #0b1f44;
  }
  .text-3 {
    text-align: left;
    vertical-align: top;
    font-size: 15px;
    font-family: Montserrat;
    line-height: auto;
    color: #44c2fa;
  }
  .text-4 {
    text-align: left;
    vertical-align: top;
    font-size: 24px;
    font-family: Montserrat;
    line-height: auto;
    color: #0b1f44;
  }
  .text-5 {
    text-align: center;
    vertical-align: top;
    font-size: 24px;
    font-family: Montserrat;
    line-height: auto;
    color: #0b1f44;
  }
  .text-6 {
    text-align: center;
    vertical-align: top;
    font-size: 70px;
    font-family: Montserrat;
    line-height: auto;
    color: #2ed28a;
  }
  .text-7 {
    text-align: center;
    vertical-align: top;
    font-size: 70px;
    font-family: Montserrat;
    line-height: auto;
    color: #2ed28a;
  }
  .text-8 {
    text-align: left;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: auto;
    color: #526c9a;
  }
  .text-9 {
    text-align: center;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: auto;
    color: #526c9a;
  }
  .text-1-0 {
    text-align: left;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: auto;
    color: #0b1f44;
  }
  .text-1-1 {
    text-align: left;
    vertical-align: top;
    font-size: 30px;
    font-family: Montserrat;
    line-height: auto;
    color: #0b1f44;
  }
  .search {
    height: 50px;
    width: 434px;
  }
  .rectangle-1-6 {
    border-radius: 1.5625rem;
    height: 50px;
    width: 434px;
    background-color: #efedf6;
  }
  .rectangle-1-7 {
    border-radius: 1.5625rem;
    height: 50px;
    width: 212px;
    background-color: #fa5720;
  }
  .text-1-2 {
    text-align: center;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 69.9999988079071%;
    color: #ffffff;
  }
  .text-1-3 {
    text-align: left;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 69.9999988079071%;
    color: #0b1f44;
  }
  .search {
    height: 50px;
    width: 221px;
  }
  .rectangle-1-6 {
    border-radius: 1.5625rem;
    height: 50px;
    width: 214px;
    background-color: #efedf6;
  }
  .rectangle-1-7 {
    border-radius: 1.5625rem;
    height: 50px;
    width: 108px;
    background-color: #fa5720;
  }
  .text-1-4 {
    text-align: center;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 69.9999988079071%;
    color: #ffffff;
  }
  .text-1-5 {
    text-align: center;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 69.9999988079071%;
    color: #0b1f44;
  }
  .rectangle-2-1 {
    border-radius: 0.25rem;
    height: 743px;
    width: 486px;
    background-color: #ffffff;
  }
  .text-1-6 {
    text-align: right;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 69.9999988079071%;
    color: #0b1f44;
  }
  .text-1-7 {
    text-align: center;
    vertical-align: top;
    font-size: 24px;
    font-family: Montserrat;
    line-height: auto;
    color: #0b1f44;
  }
  .text-1-8 {
    text-align: center;
    vertical-align: top;
    font-size: 70px;
    font-family: Montserrat;
    line-height: auto;
    color: #2ed28a;
  }
  .text-1-9 {
    text-align: center;
    vertical-align: top;
    font-size: 70px;
    font-family: Montserrat;
    line-height: auto;
    color: #2ed28a;
  }
  .text-2-0 {
    text-align: center;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: auto;
    color: #526c9a;
  }
  .text-2-1 {
    text-align: left;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: auto;
    color: #0b1f44;
  }
  .text-2-2 {
    text-align: left;
    vertical-align: top;
    font-size: 30px;
    font-family: Montserrat;
    line-height: auto;
    color: #0b1f44;
  }
  .search {
    height: 50px;
    width: 221px;
  }
  .rectangle-1-6 {
    border-radius: 1.5625rem;
    height: 50px;
    width: 214px;
    background-color: #efedf6;
  }
  .rectangle-1-7 {
    border-radius: 1.5625rem;
    height: 50px;
    width: 108px;
    background-color: #2ed28a;
  }
  .text-2-3 {
    text-align: center;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 69.9999988079071%;
    color: #0b1f44;
  }
  .text-2-4 {
    text-align: center;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 69.9999988079071%;
    color: #ffffff;
  }
  .h-e-a-d-e-r {
    height: 73px;
    width: 1604px;
  }
  .rectangle-2 {
    height: 73px;
    width: 1604px;
    background-color: #ffffff;
  }
  .text-2-5 {
    text-align: left;
    vertical-align: top;
    font-size: 24px;
    font-family: Montserrat;
    line-height: auto;
    color: #c4c4c4;
  }
  .mask-group {
    height: 33px;
    width: 33px;
  }
  .ellipse-1 {
    height: 33px;
    width: 33px;
  }
  .img-2-6 {
    height: 32px;
    width: 49px;
  }
  .text-2-7 {
    text-align: left;
    vertical-align: bottom;
    font-size: 15px;
    font-family: Montserrat;
    line-height: auto;
    color: #0b1f44;
  }
  .menu {
    height: 1080px;
    width: 316px;
  }
  .rectangle-1 {
    border-radius: 0rem 0rem 0.25rem 0rem;
    height: 1080px;
    width: 316px;
    background-color: #0b1f44;
  }
  .text-2-8 {
    text-align: left;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: auto;
  }
  .img-2-9 {
    height: 20px;
    width: 21px;
  }
  .img-3-0 {
    height: 25px;
    width: 23px;
  }
  .img-3-1 {
    height: 20px;
    width: 20px;
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    src: url(/static/media/Montserrat-Regular.3cd78665.ttf);
  }
  @font-face {
    font-family: 'Montserrat-SemiBold';
    src: url(/static/media/Montserrat-SemiBold.fa8441f3.ttf);
  }
  @font-face {
    font-family: 'Montserrat-Bold';
    src: url(/static/media/Montserrat-Bold.079ca05d.ttf);
  }
  label,a,p,h1,h2,h3,h4,h5,h6,input{
    font-family: 'Montserrat';
  }
  body{
    font-family: 'Montserrat';
  }
.label-bold{
  font-family: 'Montserrat-Bold';
}
.content
{
  background: #ffffff;
}
.form-select,.form-control,button,.btn
{
  border-radius: 15px;
  font-weight: 600;
}
.div-content{
margin-top: 1rem;
margin-bottom: 1rem;
}
.btn-write{
  background: white;
  
  background: white;
  border: none;
 
}
tbody{
  color: #526c9a;
}
#programa_Habilitado,#programa_Tiene_Vigencia
{
  font-size: 20px;
  margin-left: -30px;
}


.form-check-input {
  background-color: #FA5720;
  border-color: #FA5720;
}

.form-check-input:checked {
  background-color: #2ED28A;
  border-color: #2ED28A;
}
label{
  color: #526C9A;
  font-family: 'Montserrat-SemiBold';
}
.fw-bold {
  color: #0B1F44;
}
.card-verde{
  background: linear-gradient(to right, #27936e, #2ed28a);
}
.btn-outline-success{
  border-color: #2ED28A !important;
  color: #2ED28A !important;
  background: #fff;
}
.btn-outline-success:hover {
  background-color: #2ED28A !important;
  border-color: #2ED28A !important;
  color: #fff !important;
}
 
.btn-outline-warning{
  border-color: #FA5720 !important;
  color: #FA5720 !important;
  background: #fff;
}
.btn-outline-warning:hover {
  background-color: #FA5720 !important;
  border-color: #FA5720 !important;
  color: #fff !important;
}
.bm-burger-button
{
  top: 15px !important;
}
.Swiperprincipal .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  content: '‹' !important;
  color: aliceblue;
}
.Swiperprincipal .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
  content: '›' !important;
  color: aliceblue;
}
.mySwiperPromotions .swiper-wrapper{
  padding-top: 30px;
  padding-bottom: 30px;
}
.SwiperCanjesE .imgCanje{
  width: auto;
  display: unset;
  height: 200px;
  font-size: 2.5vw;
}
.SwiperCanjesT .imgCanje{
  width: 150px;
  height: 150px;
  margin-left: 40%;
  font-size: 2.5vw;
}
.SwiperCanjesM .imgCanje{
  width: 100px;
  height: 100px;
  margin-left: 35%;
  font-size: 3vw;
}

.SwiperCanjesE h4{
  padding-top: 14px;
}
.SwiperCanjesT h4{
  padding-top: 14px;
}
.SwiperCanjesM h4{
  padding-top: 14px;
}

.SwiperCanjesE p{
  font-size: 25px;
  padding-bottom: 15px;
}
.SwiperCanjesT p{
  font-size: 18px;
  padding-bottom: 15px;
}
.SwiperCanjesM p{
  font-size: 18px;
  padding-bottom: 15px;
}

.SwiperCanjesE .imgCanjemoneda{
  width: 80px;
  height: 80px;
  float: left;
  position: absolute;
  left: 5%;
}
.SwiperCanjesE .imgCanjetick{
  width: 70px;
  height: 35px;
  position: absolute;
  right: 5%;
  top: 80px;
}
.SwiperCanjesE .imgCanjetrofeo{
  width: 60px;
  height: 50px;
  position: absolute;
  bottom: 0px;
  left: 20%;
}


.SwiperCanjesT .imgCanjemoneda{
  width: 50px;
  height: 50px;
  float: left;
  position: absolute;
  left: 5%;
}
.SwiperCanjesT .imgCanjetick{
  width: 60px;
  height: 25px;
  position: absolute;
  right: 1%;
  top: 80px;
}
.SwiperCanjesT .imgCanjetrofeo{
  width: 50px;
  height: 40px;
  position: absolute;
  bottom: 0px;
  left: 20%;
}

.SwiperCanjesM .imgCanjemoneda{
  width: 50px;
  height: 50px;
  float: left;
  position: absolute;
  left: 5%;
}
.SwiperCanjesM .imgCanjetick{
  width: 60px;
  height: 25px;
  position: absolute;
  right: 1%;
  top: 80px;
}
.SwiperCanjesM .imgCanjetrofeo{
  width: 50px;
  height: 40px;
  position: absolute;
  bottom: 0px;
  left: 20%;
}

@media (max-width: 800px) and (min-width: 520px){
  .SwiperCanjesT .imgCanjetrofeo,.SwiperCanjesT .imgCanjetick,.SwiperCanjesT .imgCanjemoneda
  {
    display: none;
  }
}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  content: '‹' !important;
  color: #007aff;
}
.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
  content: '›' !important;
  color: #007aff;
}


.cardTotalPuntos span,.cardTotalPuntos p,
.cardImpoteTotal span,.cardImpoteTotal p,
.cardUsuarios span,.cardUsuarios p,
.cardReferidos span,.cardReferidos p{
  color: #ffffff !important;
}
.cardTotalPuntos{
  background: linear-gradient(to right, #6850ca, #9782e9);
}
.cardImpoteTotal{
  background: linear-gradient(to right, #27926e, #47d798);
}

.cardUsuarios{
  background: linear-gradient(to right, #fa5720, #f7a603);
}

.cardReferidos{
  background: linear-gradient(to right, #5a73e8, #44c2fa);
}

@media (max-width: 800px){
  .btn-carrousel-home-index{
    background: rgb(24, 154, 214);
    color: rgb(237, 247, 251);
    border: none;
    /*font-size: 12px;*/
    padding: 7px 15px 7px 15px;
    border-radius: 30px;
    pointer-events: all;
    min-width: 154px;
  }
  .img-fotter-app{
    height: 10vw !important;
  }
  .header-home-index a, .header-home-index label {
    font-size: 12px;
    padding: 0px;
  }
  .header-home-index .col-5{
    margin-right: 155px !important;
    z-index: 10 !important;
  }
  .derechos-reservados{
    bottom: 0px !important;
    position: relative !important;
  }
}
@media (min-width: 800px){
  .btn-carrousel-home-index{
    background: rgb(24, 154, 214);
    color: rgb(237, 247, 251);
    border: none;
    font-size: 16px;
    padding: 10px 30px 10px 30px;
    border-radius: 30px;
    pointer-events: all;
    min-width: 180px;
  }


}


@media (max-width: 650px){
  .header-home-index label{
    font-size: 12px;
    line-height: 10px;
  }
  .header-home-index img{
    height: 2.5vw !important;
    margin-top: 25px !important;
    margin-left: 5px !important;
    max-width: none !important;
    max-width: initial !important;
  }
  .fyttsa-club{
    height: 15px !important;
  }
}


.derechos-reservados p{
  font-size: 10px;
}

@media (max-width: 650px){
.h1-suscripcion-cantidad{
  font-size: 12vw !important;

}
.h1-suscripcion-puntos{
  font-size: 8vw !important;
}

.derechos-reservados p{
  font-size: 8px;
}
.derechos-reservados{
  bottom: 0px !important;
}

.SwiperCliente .swiper-pagination{
  bottom: 0px !important;
}
}


@media (max-width: 500px){
  .footer h3{
    font-size: 16px;
  }
  .footer h6{
    font-size: 0.5rem !important;
  }
  .footer h1{
    font-size: 21px;
  }
  .footer p{
    font-size: 9px;
  }
  .footer label{
    font-size: 10px;
  }
  
  .suscripcion h1{
    font-size: 13px;
  }
  
  .suscripcion br{
    display: none;
  }
  .suscripcion input{
    font-size: 12px;
    margin-bottom: 10px;
  }
}


.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50%;
  background: #ffffff;
  opacity: .8;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--swiper-theme-color);
  background: var(--swiper-pagination-color,var(--swiper-theme-color));
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 55px;
}

.header-div{
  z-index: 10;
  position: relative;
}
.Swiperprincipal{
  width: 100%;
  height: 100vh;
  margin-top: -70px;
}
.Swiperprincipal .swiper-slide
{
  padding-top: 70px;
}



.SwiperCliente{
  margin-top: 15px;
}


.containerImgSlider{
  height: 100%;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  background-attachment: fixed;
}

.containerImgSliderMovil{
  height: 100%;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  background-attachment: fixed;
  z-index: 100;
}
.btn-carrousel-home-index{
  font-family: 'Montserrat-Bold';
}

@media (min-width: 2000px){
.btn-carrousel-home-index{
  font-size: 2.5rem;
  padding: 30px 30px 30px 30px;
  min-width: 400px;
}

.login .img-fyt{
  width: 400px !important;
}
.login h2{
  font-size: 60px;
}
.login p,.login input, .login a, .login small, .login select{
  font-size: 30px !important;
}
.login option{
  font-size: 18px !important;
}
.login .derechos-reservados p{
  font-size: 20px;
}
.footer p{
  font-size: 20px;
}
.suscripcion  input, .suscripcion  button, .suscripcion  label{
  font-size: 25px;
}

.header-home-index p{
  font-size: 17px;
}
.header-home-index a{
  font-size: 16px;
}

}

@media (max-width: 700px){
.header-home-index  a,.header-home-index  label,
.row-home-cliente  a,.row-home-cliente  label{
font-size: 10px;
padding: 0px;
}

.header-home-index .col-2,.header-home-index .col-sm-2{
  max-width: 119px  !important;
  min-width: 119px  !important;
  right: 0px !important;
}
.header-home-index .col-5{
  margin-right: 110px !important;
  z-index: 10 !important;
}

}

@media (max-width: 500px){
  .header-home-index .col-5{
    margin-right: 95px !important;
    z-index: 10 !important;
  }
  .header-home-index .col-5 a,.header-home-index .col-5 label{
    font-size: 8px;
    padding: 0px;
  }
  .header-div .col-8{
    max-width: 180px !important;
  }
}
.modal-close
{
  z-index: 100;
}
.login .card-footer{
  background: transparent !important;
}
.row-home-cliente
{
--bs-gutter-x: 1.5rem;
--bs-gutter-y: 0;
display: -webkit-flex;
display: flex;
}
.row-home-cliente .login{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.row-home-cliente .imagen{
display: -webkit-flex;
display: flex;
-webkit-align-items: center;
        align-items: center;
}

.row-home-cliente .col-3 img{
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 60px;
}

@media (max-width: 575px){
  .row-home-cliente .col-6 div{
    max-width: 160px;
    margin: 0px auto;
  }
  .row-home-cliente .col-6 label, .row-home-cliente .col-6 a{
    font-size: 11px;
    line-height: 20px;
  }
  .row-home-cliente .col-3{
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (min-width: 576px){
  .row-home-cliente .col-sm-6 div{
    max-width: 350px;
    margin: 0px auto;
    margin-right: 0px;
    margin-top: 10px;
  }
  .row-home-cliente .login a{
    display: table-cell;
    vertical-align: middle;
    width: 100%;
  }
}
@media (min-width: 768px){
  .row-home-cliente .col-3 img{
    max-width: 80%;
    margin-left: 10%;
  }

  .row-home-cliente .col-md-6 div{
  font-size: 14px;
   }
  .row-home-cliente .col-md-6 div{
    max-width: 350px;
    margin: 0px auto;
    margin-right: 0px;
    margin-top: 10px;
  }
  .row-home-cliente .login a{
    display: table-cell;
    vertical-align: middle;
    width: 100%;
  }
@media (min-width: 2000px){
  .row-home-cliente .col-md-6 div{
    max-width: 450px;
    font-size: 21px;
  }
}
  
}
