body{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: #F8F7FC;
}

.fondo-form {
    position: absolute;
    width: 40%;
    height: 100%;
    left: 60%;
    top: 0px;
    bottom: 0px;
    background: #0B1F44;
    color: #F8F7FC;
}

.logo{
    position: absolute;
    left: 80%;
    right: 15.52%;
    top: 7.66%;
    bottom: 84.48%;
}

.button-login{
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    width: 50%;
    background: #44C2FA;
    mix-blend-mode: normal;
    border-radius: 30px;
    color: #F8F7FC;
}

.button-second{
    padding: 5px;
    width: 30%;
    background: #173161;
    border-radius: 30px;
    color: #ACBAD2;
}

.img-ilustracion1{
    height: auto;
    width: 50%;
    margin-top: 1vh;
}

.emonederologo{
    height: auto;
    width: 20%;
    margin-left: 2%;
    margin-top: 1%;
}

.fyttsalogo{
    height: 5vh;
    width: auto;
}

.textprimary{
    color: #5A73E8;
}

.textsecond{
    color: #526C9A;
}

.white{
    color: #ffffff;
}

.whitelight{
    color: #F8F7FC;
}
.colorplaceholder{
    color: #526C9A;
}

.transparent{
    background: transparent;
}
.olvidoclave{
    width:48%; 
    text-align:left;  
    display: inline-block;
}
.login-icons svg{
   margin-left: 20px;
}

.login-icons img{
  width: 30px;
  display: initial !important;
}

/* mitad login*/
.col-md-8 .img-blanco{
   z-index: 1;
   width: 140px;
   margin-left: 60%;
   position: relative;
}
.col-md-8 .img-centro{
  z-index: 0;
  width: 450px;
  display: initial;
  margin-top: -140px;
  position: relative;
}
.col-md-8 .img-logoFyttsa{
  z-index: 0;
  width: 300px;
  display: initial;
}
.div-emonedero
{
  width: 250px;
  margin-left: 60px;
  margin-top: 10px;
}
.img-fyt
{
  width: 150px;
  display: initial;
  margin-top: 10%;
  margin-bottom: 15%;
}

@media screen and (max-width: 768px) {
  .col-6 .img-blanco{
    z-index: 1;
    width: 15vw;
    margin-left: 65%;
    position: relative;
  }
  .col-6 .img-centro{
   z-index: 0;
   width: 50vw;
   display: initial;
   margin-top: -40px;
   position: relative;
  }
  .col-6 .img-logoFyttsa{
   z-index: 0;
   width: 40vw;
   display: initial;
  }
  .div-emonedero
  {
    width: 150px;
    margin-left: 0px;
    margin-top: 10px;
  }
  .img-fyt
  {
    display: initial;
    margin-top: 10%;
    height: 25px;
    width: auto;
  }
  .login .col-6
  {
    font-size: 3.9vw;
  }
  }
  .login input[type="text"], .login input[type="password"]{
    border-radius: 30px !important;
    background: #ffffff !important;
    border-color: #183161 !important;
    color: #003869 !important;
  }
  
  .login .btn{
    border-radius: 20px !important;
  }


